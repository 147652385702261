<template>
  <el-main class="simple1-container">
    <section class="inner-container">
      <div class="section-h2">
        <MainContain />
        傳接球實驗數據股份有限公司
      </div>
      <p>2024年3月</p>
      <div class="image-container">
        <img :src="image" class="image" alt="">
        <p class="image_desc">以低成本高效率的數據協助選手提升實力</p>
        <p class="image_desc">(圖片提供：傳接球實驗數據股份有限公司)</p>
      </div>
      <div class="image-container">
        <img :src="image2" class="image" alt="">
        <p class="image_desc">智慧牛棚擴散效益以台南市為範例</p>
        <p class="image_desc">(圖片提供：傳接球實驗數據股份有限公司)</p>
      </div>
      <div class="content-section">
        <div class="section-h3">實證目標</div>
        <div class="content-desc">
          棒球為深受國民喜愛之台灣國球，台灣棒球產業正在尋求運⽤數據科技來提升運動表現、球員發展和產業發展。這些需求涵蓋了多個⽅⾯，包括投⼿控球技巧的數據分析、疲勞監測、球員訓練數據的取得與分析、整合棒球資訊報告平台、提升球員國際曝光機會、建立運動履歷表、以及建立棒球實證場域供新創業者研發新的數據應⽤。這些需求的核⼼是透過數據化分析和科技應⽤來改善選⼿的技術⽔準、提升整體棒球產業的競爭⼒，並增強球員在國際舞台上的能⾒度，但必須解決⽬前常⾒數據化分析和科技應⽤操作複雜、成本過⾼等痛點，累積運科在台灣基層的成功案例，故傳接球實驗數據提供低門檻、低干擾，易擴散的數據蒐集/回饋系統。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據貢獻活動</div>
        <div class="content-desc">
          提供低操作⾨檻、⾃動化且不影響球員訓練模式的數據搜集及回饋系統，針對現有訓練需求提供關鍵數據視覺化呈現，提升教練及球員使⽤意願，將⼤量且全⾯骨架資訊搜集的可⾏性⼤幅上升，且與傳統貼光點的方式相較(貼一人需要3小時)，大幅降低蒐集時間，有利推廣與公益用途，可用於訓練、運動傷害預防等數據分析應用。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據應用模式</div>
        <div class="content-desc">
          利用投球影像/性別/慣用手/層級/年齡/球速/肘屈@FC 前腳膝屈@FC/MER/前腳膝屈等建立台灣棒球運動表現常模，有助於運動員了解⾃⾝運動表現與常模之比較。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據應用效益/展望</div>
        <div class="content-desc">
          本案除可成功建立台灣棒球投球數據常模，並提供⼤數據進階分析及應⽤之效益，對於個別球員也可結合運動專項領域知識提供改善⽅向建議及客製化訓練處⽅簽
        </div>
      </div>
    </section>
  </el-main>
</template>

<script>
import MainContain from '@/components/Accessibility/MainContain.vue'

export default {
  name: 'UseCase11210',
  components: {
    MainContain
  },
  data () {
    return {
      image: require('@/assets/images/sample/112-10-1.png'),
      image2: require('@/assets/images/sample/112-10-2.png')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.simple1-container{
  .inner-container{
    max-width: 800px;
    margin: 0 auto;
    padding: 1.25rem 0;
  }
  .image-container{
    padding: 1.5rem 0;
    .image {
      width: 80%;
      display: block;
      margin: 0 auto;
      }
    .image_desc{
      text-align: center;
    }
  }
  
  .content-section{
    
    .content-desc{
      line-height: 1.6;
    }
  }
}

</style>
